.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 900px) {
  .background {
    padding: 25px;
  }
}

// .background::after {
//   content: "";
//   background: #ece4cd;
//   height: 200px;
//   left: 0;
//   position: absolute;
//   top: 0;
//   width: 100%;
//   z-index: 1;
// }

// @media (min-width: 900px) {
//   .background {
//     padding: 0 0 25px;
//   }
// }

.why-us-container {
  margin: 0 auto;
  padding: 50px 0 0;
  max-width: 860px;
  width: 100%;
}

@media (min-width: 900px) {
  .why-us-container {
    padding: 0;
  }
}

.panel {
  background-color: #fff;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.pricing-table {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
    0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
}

@media (min-width: 900px) {
  .pricing-table {
    flex-direction: row;
  }
}

.pricing-table * {
  text-align: center;
}

.pricing-plan {
  border-bottom: 1px solid #e1f1ff;
  padding: 5px;
}

.pricing-plan:last-child {
  border-bottom: none;
}

@media (min-width: 900px) {
  .pricing-plan {
    border-bottom: none;
    border-right: 1px solid #e1f1ff;
    flex-basis: 100%;
    padding: 5px 10px;
  }

  .pricing-plan:last-child {
    border-right: none;
  }
}

.pricing-img {
  margin-bottom: 5px;
  max-width: 20%;
}
@media (min-width: 900px) {
  .pricing-img {
    max-width: 50%;
  }
}

.pricing-header {
  color: #888;
  font-weight: 600;
  letter-spacing: 1px;
}

.pricing-features {
  font-weight: 600;
  letter-spacing: 1px;
  margin: 10px 0 5px;
}

.pricing-features-item {
  border-top: 1px solid #e1f1ff;
  font-size: 12px;
  line-height: 1.5;
  padding: 15px 0;
}

.pricing-features-item:last-child {
  border-bottom: 1px solid #e1f1ff;
}
