.emblahome {
  margin: auto;
  position: relative;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 70%;
}
.emblahome__viewport {
  overflow: hidden;
}
.emblahome__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.emblahome__slide {
  flex: 0 0 100%;
  min-width: 0;
  padding-left: var(--slide-spacing);
}

// .emblahome__controls {
//   display: grid;
//   grid-template-columns: auto 1fr;
//   justify-content: space-between;
//   gap: 1.2rem;
//   margin-top: 1.8rem;
// }
// .emblahome__buttons {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 0.6rem;
//   align-items: center;
// }
.emblahome__button {
  -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.emblahome__button:disabled {
  color: var(rgb(192, 192, 192));
}
.emblahome__button__svg {
  width: 35%;
  height: 35%;
}
.emblahome__dots {
  align-items: center;
  margin-right: calc((1.6rem - 1.4rem) / 2 * -1);
}
.emblahome__dot {
  -webkit-tap-highlight-color: rgba(49, 49, 49, 0.5);
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.emblahome__dot:after {
  box-shadow: 0 0 0 0.1rem rgb(255, 255, 255);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.emblahome__dot--selected:after {
  box-shadow: inset 0 0 0 0.1rem rgb(174 128 72);
}
