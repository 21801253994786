@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "searchTour";
@import "navbar";
@import "slider";
@import "emblaCarousel";
@import "whyuspointsection";

//Fonts

@font-face {
  font-family: "MazzardMedium";
  src: url("../../public/assets/fonts/mazzard-medium.woff2");
}

@font-face {
  font-family: "Sebastian";
  src: url("../../public/assets/fonts/SebastianBobbyAlt-Slanted.woff2");
}

body {
  overflow-x: hidden !important;
  @apply bg-colorBackground font-fontMain text-colorTextBase overflow-x-hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15));
  border: 1px solid rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4));
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-4px);
  }
  20% {
    transform: translateX(4px);
  }
  30% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  60% {
    transform: translateX(0);
  }
}

@layer utilities {
  .animate-shake {
    animation: shake 0.5s;
  }
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d8c699;

  // .contact {
  //   margin-top: 80px;
  //   display: flex;

  //   h5 {
  //     font-weight: normal;

  //     &:first-of-type {
  //       margin-right: 40px;
  //     }
  //   }
  // }
}
