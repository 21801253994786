.nav__menu {
  height: 300px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 64px;
  transition: opacity 0.1s, visibility 0.1s;
  visibility: hidden;
  width: 100%;
  z-index: 100;
}
.nav__item .nav__menu .menu {
  opacity: 0;
}
.nav__item:hover .nav__menu {
  opacity: 1;
  visibility: visible;
}
.nav__item:hover .nav__menu .menu {
  opacity: 1;
  transition: opacity 0.1s 0.2s;
}
.nav__item:hover .nav__link {
  color: var(--main-color-first);
}
