/* EMBLA CSS HOME SLIDER*/

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
}
.embla__destination-mobile-container {
  display: grid;
  grid-column-gap: 1.25rem;
  grid-auto-flow: column;
  grid-auto-columns: 70%;
}
.embla__destination-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 50%;
}

/* EMBLA CSS DESTINATION SLIDER */
.slick-track {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
