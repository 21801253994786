/* custom checkbox */

.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
  color: #353535;
}

.custom-checkbox label:before {
  content: "";
  @apply bg-colorCard border border-colorTextBase;
  padding: 8px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  top: -2px;
}

.custom-checkbox input:checked + label::before {
  @apply bg-colorTextSecond;
}

.custom-checkbox input:checked + label {
  color: #1e1e1e;
}

.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6.5px;
  width: 5px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
